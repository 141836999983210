/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { Dropdown, Navbar, Nav, Offcanvas } from "react-bootstrap";

/* IMAGE IMPORTS */
import Logo from "../../assets/amberwood-logo.png";

import MenuIcon from "@iconscout/react-unicons/icons/uil-bars";
import { useDispatch, useSelector } from "react-redux";
import actions from "../../redux/actions/userAction";

const DashboardHeader = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);

  /* HANDLE LOGOUT */
  const logout = () => {
    localStorage.clear();
    dispatch(actions.setUser(null));
    dispatch(actions.setToken(null));
    dispatch(actions.setLoggedin(false));
    navigate("/");
  };

  return (
    <header className="dashboard-header">
      <Navbar expand="lg">
        <div className="container" style={{ display: "block" }}>
          <div className="navigation">
            <div className="left-navigation">
              <div className="logo-box">
                <Link to="/contacts">
                  <img src={Logo} className="header-logo" alt="Company Logo" />
                </Link>
              </div>
              <Navbar.Toggle aria-controls="responsive-navbar-nav">
                <MenuIcon color="#FFF" size="25" />
              </Navbar.Toggle>
              <Navbar.Offcanvas
                id="responsive-navbar-nav"
                className="mobile-menu"
              >
                <Offcanvas.Header className="mobile-menu-header" closeButton>
                  <img src={Logo} className="header-logo" alt="Company Logo" />
                </Offcanvas.Header>
                <Offcanvas.Body className="mobile-menu-body">
                  <Nav className="navigation-options">
                    <Nav.Link
                      href="/contacts"
                      className="navigation-item"
                      active={
                        window.location.pathname === "/contacts" ? true : false
                      }
                    >
                      Get in Touch
                    </Nav.Link>
                    {/* <Nav.Link
                      href="/agents"
                      className="navigation-item"
                      active={
                        window.location.pathname === "/agents" ? true : false
                      }
                    >
                      Agents
                    </Nav.Link>
                    <Nav.Link
                      href="/blogs"
                      className="navigation-item"
                      active={
                        window.location.pathname === "/blogs" ? true : false
                      }
                    >
                      Blogs
                    </Nav.Link>
                    <Nav.Link
                      href="/events"
                      className="navigation-item"
                      active={
                        window.location.pathname === "/events" ? true : false
                      }
                    >
                      Events
                    </Nav.Link> */}
                    {/* <Nav.Link
                      href="/newsletter"
                      className="navigation-item"
                      active={
                        window.location.pathname === "/newsletter"
                          ? true
                          : false
                      }
                    >
                      Newsletter
                    </Nav.Link> */}
                    <Nav.Link
                      href="/home-evaluation"
                      className="navigation-item"
                      active={
                        window.location.pathname === "/home-evaluation"
                          ? true
                          : false
                      }
                    >
                      Home Evaluation
                    </Nav.Link>
                  </Nav>
                </Offcanvas.Body>
              </Navbar.Offcanvas>
            </div>
            {/* RIGHT NAVIGATION */}
            <div className="right-navigation">
              <Dropdown className="profile-dropdown">
                <Dropdown.Toggle>
                  <div className="profile-avatar">
                    <div className="admin-details">
                      <p>Administrator</p>
                      <h5>{user?.loggedIn ? user.user.firstName+" "+user.user.lastName : "Admin"}</h5>
                    </div>
                    <img
                      src={require("../../assets/AMBERWOOD-TREE.jpg")}
                      alt="Profile"
                    />
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu className="profile-dropdown-menu">
                  <Dropdown.Item className="menu-item" href="/user-details">
                    View Details
                  </Dropdown.Item>
                  <Dropdown.Item onClick={logout} className="menu-item">
                    Log out
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
      </Navbar>
    </header>
  );
};

export default DashboardHeader;